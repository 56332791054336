<template>
  <div class="appoint-items-wrapper">
    <b-table-simple id="tb-proc">
      <thead>
        <tr>
          <td class="td-head" style="width: 75%">Convênio / Item</td>
          <!-- <td class="td-head">Item</td> -->
          <td class="td-head" style="width: 120px;"></td>
          <td class="td-head" style="width: 50px;"></td>
        </tr>
      </thead>
      <tbody v-for="(_form, index) in forms" :key="index">
        <tr>          
          <!-- <td class="td-body professional" style="width: 40%;">
            <b-form-group class="full-width">
              <multiselect
                class="with-border"
                track-by="id"
                label="fantasy_name"
                :value="_form.health_plan"
                :searchable="false"
                :showLabels="false"
                :allow-empty="false"
                placeholder="Selecionar"
                :options="healthPlans"
                :loading="loadingHealthPlans"
                @select="healthPlan => selectHealthPlan(healthPlan, index)"
                :disabled="disabled"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="option" slot-scope="{ option }">
                  <div class="ellipsis-wrapper">
                    <div class="ellipsis">
                      {{ option.fantasy_name }} <span v-if="option.$isDisabled">(indisponível)</span>
                    </div>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  <div class="ellipsis-wrapper">
                    <div class="ellipsis">
                      {{ option.fantasy_name }}
                    </div>
                  </div>
                </template>
                <template slot="noOptions">
                  {{ !form.patient ? 'Selecione um paciente' : 'Nenhum convênio adionado ao paciente' }}
                </template>
                <template slot="noResult">
                  Convênio informado não vinculado ao paciente
                </template>
                <template v-if="form.patient" slot="afterList">
                  <div class="new-health-plan" @click="openHealthPlansModal">Adicionar convênio</div>
                </template>
              </multiselect>
              <div v-if="validated && !_form.health_plan" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </td>
          <td class="td-items item">
            <b-form-group>
              <AppointmentItemSelector
                :item="_form.item"
                @select="item => selectItem(item, index)"
                :healthPlan="_form.health_plan"
                :disabled="disabled"
              />
              <div v-if="_form?.item?.item?.deleted_at && moment(form.start_datetime).format('YYYY-MM-D') >= moment().format('YYYY-MM-D')" class="custom-invalid-feedback">Procedimento excluído, não estará disponível na pré-consulta e exames/procedimentos, atualize o item para habilitar</div>
              <div v-else-if="validated && !_form.item" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </td> -->
          <td style="width: 75%">
            <ItemHealthPlanSelector
              v-model="_form.item"
              :patientId="form.patient?.id"
              :healthPlan="_form.health_plan"
              :plan="_form.plan"
              :availableProcedures="availableProcedures"
              :allowedTypes="[
                ITEM.ITEM_TYPE_PROCEDURE,
                ITEM.ITEM_TYPE_APPOINTMENT,
                ITEM.ITEM_TYPE_EXAM,
                ITEM.ITEM_TYPE_RETURN,
                ITEM.ITEM_TYPE_TELEMEDICINE,
                hasSurgicalCenter ? ITEM.ITEM_TYPE_SURGICAL : null,
              ]"
              @select="item => selectItem(item, index)"
            />
          </td>
          
          <td class="td-body value" style="width: 120px;">
            <div class="w-100">
              <!-- <b-form-group v-if="_form.health_plan && _form.health_plan.type !== 'particular'" class="full-width">
                <multiselect
                  :value="_form.plan"
                  :options="plans"
                  track-by="value"
                  label="name"
                  :showLabels="false"
                  :searchable="true"
                  placeholder="Plano (opcional)"
                  class="with-border full-width"
                  @select="plan => selectPlan(plan, index)"
                  @open="handleUpdatePlans(index)"
                  :disabled="!_form.health_plan || disabled"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <div class="ellipsis-wrapper">
                      <div class="ellipsis">
                        {{ option.name }} <span v-if="option.$isDisabled">(indisponível)</span>
                      </div>
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                  <div class="ellipsis-wrapper">
                    <div class="ellipsis">
                      {{ option.name }}
                    </div>
                  </div>
                </template>
                  <template slot="noOptions">
                    Não foram encontrados planos
                  </template>
                  <template slot="noResult">
                    Plano informado não identificado ou não cadastrado
                  </template>
                </multiselect>
              </b-form-group> -->
              <money
                class="form-control money"
                :value="userHasPermission('CcProc6') ? _form.value : '' "
                :disabled="!_form.health_plan || !userCanEditValues || disabled"
                @input="value => changeValue(value, index)"
                v-if="!_form.health_plan?.clinic_health_plan[0]?.invoicing_tiss_active && !_form.health_plan?.is_sus_health_plan
                && !_form.health_plan?.clinic_health_plan[0]?.invoicing_sus_active"
              />
            </div>
          </td>
          
          <td class="td-body actions" style="width: 50px;">
            <div class="actions-wrapper">
              <Icon
                v-show-feature="'onlending'"
                v-if="showOnlending() && !disabled"
                v-can="'RepMedPerf1'"
                tooltip="Perfis de repasse"
              >
                <div class="toggle-profiles-wrapper">
                  <b-badge
                    pill
                    variant="primary"
                    class="badge"
                  >
                    {{ _form.item_profiles.length }}
                  </b-badge>
                  <ChevronDown
                    class="icon-action chevron"
                    :class="{ 'icon-more-active': _form.showItemProfiles }"
                    @click="toggleShowProfiles(index)"
                  />
                </div>
              </Icon>
              <Delete class="icon-action" @click="removeItem(index)" v-if="!disabled"/>
            </div>
          </td>
        </tr>
        <tr v-if="_form.showItemProfiles">
          <td class="td-body" colspan="4">
            <OnlendingProfilesModule
              :item="_form.item"
              :itemProfiles="_form.item_profiles"
              @change="(itemProfiles) => changeItemProfiles(index, itemProfiles)"
            />
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div class="footer">
      <div
        class="add-button"
        @click="forms.push(defaultForm())"
        v-if="!disabled"
      >
        <Plus class="plus" />
        Adicionar item
      </div>
      <div
        class="add-button"
        @click="forms.push(defaultForm())"
        v-if="disabled"
      ></div>
      <ValueTotal
        v-can="'CcProc6'"
        label="Valor total"
        v-if="getTotal() > 0 && (forms && !forms[0].health_plan?.is_sus_health_plan && !forms[0].health_plan?.clinic_health_plan[0]?.invoicing_sus_active)"
        :value="getTotal()"
      />
    </div>
    <PatientHealthPlanModal 
      :editIndex="null"
      :addPersonHealthPlan="addPersonHealthPlan"
    />
  </div>
</template>
<script>
import * as ITEM from '@items/utils/constants'
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
import { showOnlending, showFeature } from '@/utils/permissionsHelp';

export default {
  props: {
    form: Object,
    allPlans: Array,
    plans: Array,
    validated: Boolean,
    healthPlans: Array,
    defaultHealthPlan: {
     type: Object,
     default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { 
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'), 
    Plus: () => import('@/assets/icons/plus.svg'), 
    Icon: () => import('@/components/General/Icon'), 
    Delete: () => import('@/assets/icons/delete.svg'), 
    // AppointmentItemSelector: () => import('@/components/AppointmentModal/AppointmentItemSelector'), 
    ValueTotal: () => import('@/components/General/ValueTotal'), 
    OnlendingProfilesModule: () => import('@/components/General/OnlendingProfilesModule'),
    PatientHealthPlanModal: () => import('@/views/Patients/PatientHealthPlanModal'),

    // Items
    ItemHealthPlanSelector: () => import('@items/components/ItemHealthPlanSelector'),
  },
  data() {
    return {
      ITEM,
      clinic: getCurrentClinic(),
      forms: [this.defaultForm()],
      availableProcedures: [],
      loadingHealthPlans: false,
      userCanEditValues: false
    }
  },
  computed: {
    hasSurgicalCenter() {
      return showFeature('Centro cirúrgico')
    }
  },
  created() {
      this.userCanEditValues = userHasPermission('FpAgen7')
    },
  methods: {
    userHasPermission,
    showOnlending,
    showFeature,
    toggleShowProfiles(index) {
      this.forms[index].showItemProfiles = !this.forms[index].showItemProfiles
      this.$forceUpdate()
    },
    defaultForm() {
      return {
        health_plan: null,
        plan: null,
        item: null,
        value: 0,
        item_profiles: []
      }
    },
    removeItem(index) {
      if (this.forms.length === 1) {
        this.$toast.warning('O agendamento deve conter ao menos um item')
        return
      }
      if (this.forms[index]?.id) {
        this.$emit('removeItem', this.forms[index].id)
      }
      this.forms.splice(index, 1)
    },

    validateTelemedicine () {
      if ((this.form?.patient && this.form?.professional) && (!this.form?.patient?.email || !this.form?.professional?.email))
        this.$toast.warning('Agendamentos de telemedicina devem conter emails válidos no cadastro do paciente e do médico')
    },

    async selectItem({ healthPlan, plan, item }, index) {
      if (item?.item?.type === ITEM.ITEM_TYPE_TELEMEDICINE)
        this.validateTelemedicine()

      this.$set(this.forms[index], 'health_plan', healthPlan)
      this.$set(this.forms[index], 'plan', plan)

      // calculate item value
      if (healthPlan.fantasy_name.includes('Particular') ) {
        item.value = item.health_plan_props.value;
      } else {
        item.value = item.health_plan_props?.medical_fee;
      }

      this.$set(this.forms[index], 'item', item)

      try {
        const { data } = await this.api.getRoomsByItem(this.clinic.id, item.id)
        this.$set(this.forms[index], 'rooms', data)
      } catch(err) {
        this.$toast.error(err.message)
      }

      this.changeValue(item.health_plan_props.value || item.health_plan_props.medical_fee, index)
      this.updateForm()
      this.getAvailableProcedures(this.forms)
      this.checkDerivativesItems(item.id)
    },
    checkDerivativesItems(itemId) {
      this.api.getItemDerivatives(this.clinic.id, itemId)
      .then(({ data }) => {
        data?.length && this.$swal({
          icon: 'question',
          html: `<div class="body-alert">Este item possui procedimentos ou produtos derivados, deseja adicioná-los?</div>`,
          showCancelButton: true,
          confirmButtonColor: '#305BF2',
          confirmButtonText: 'Adicionar',
          cancelButtonText: 'Não, obrigado',
          reverseButtons: true
        }).then(res => {
          if (res.isConfirmed) {
            this.addDerivatives(data)
          }
        })
      })
    },
    selectHealthPlan(healthPlan, index) {      
      this.forms[index].health_plan = healthPlan
      this.forms[index].item = null
      this.forms[index].plan = null
      this.forms[index].value = 0
      this.$emit("updatePlans", healthPlan)
      this.getItemPrice(index)
      this.updateForm()
    },
    selectPlan(plan, index) {
      this.forms[index].plan = plan
      this.updateForm()
    },
    handleUpdatePlans(index){
      this.$emit("updatePlans", this.forms[index].health_plan)
    },
    changeValue(value, index) {
      this.forms[index].value = value
      this.updateForm()
    },
    changeItemProfiles(index, itemProfiles) {
      this.forms[index].item_profiles = itemProfiles
      this.updateForm()
    },
    getAvailableProcedures(appoitmentItens) {
      this.api.getAvailableProcedures(appoitmentItens.map(el => el.item.id))
        .then(({ data }) => {
          this.availableProcedures = data
        })
    },
    getTotal() {
      return this.forms.reduce((acc, form) => {
        if(!form.health_plan?.clinic_health_plan[0]?.invoicing_tiss_active){
          acc += form.value || 0
        }
        return acc
      }, 0)
    },
    updateForm() {
      this.$emit('change', { items: this.forms, availableProcedures: this.availableProcedures })
    },
    addDerivatives(derivatives) {
      derivatives.map(derivative => {
        const item = this.defaultForm();
        item.item = derivative;
        item.item_type = derivative.item_type;
        this.forms.push(item);
      })
      this.updateForm()
    },
    openHealthPlansModal() {
      this.$bvModal.show('new-medical-insurance-modal')
    },
    async addPersonHealthPlan(personHealthPlan) {
      this.loadingHealthPlans = true
      try {
        const payload = {
          person_id: this.form.patient.id,
          health_plan_id: personHealthPlan.health_plan?.id,
          plan_id: personHealthPlan.plan?.id,
          registration: personHealthPlan.registration,
          token: personHealthPlan.token,
          valid_until: personHealthPlan.valid_until,
          holder: personHealthPlan.holder,
        }
        await this.api.createHealthPersonPlan(payload)
        this.$bvModal.hide('new-medical-insurance-modal')
        this.$emit('validatedPlans', true);
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loadingHealthPlans = false
      }
    }
  },
  watch: {
    form() {
      if (this.form?.appointment_items.length) {
        this.forms = this.form.appointment_items
        this.getAvailableProcedures(this.forms)
      }
    },
    defaultHealthPlan(newValue) {
      this.forms[0].health_plan = newValue
    }
  }
}
</script>
<style lang="scss" scoped>
.appoint-items-wrapper {
  margin: 20px 0;
}
#tb-proc{
  table-layout: fixed;
}
tbody {
  border: none;
  border-bottom: 1px solid var(--neutral-200);
}
.td-head{
  border: none;
  background-color: var(--neutral-100);
  color: var(--dark-blue);
  font-weight: 700;
  font-size: 14px;
  &:first-child {
    border-radius: 8px 0 0 0;
  }
  &:last-child {
    border-radius: 0 8px 0 0;
  }
}
td {
  border: none !important;
}
.add-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  font-weight: 600;
  color: var(--blue-500);
  margin: 0 -8px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: var(--neutral-100);
  }
}
.td-items {
  width: 60%;
  &.item {
    padding: 0.75em 8px;
  }
}
.td-body {
  &.professional {
    padding: 0.75em 8px 0.75rem 0;
  }
  &.value {
    padding: 0.75em 8px 0.75rem 0;
  }
  &.actions {
    padding: 1rem 8px;
  }
}
.flex {
  display: flex;
  gap: 10px;
}
.full-width {
  width: 100%;
}
.form-group {
  margin-bottom: 0px;
}
.width-200{
  width: 200px;
}
.money {
  margin-bottom: 8px;
}
.plus {
  width: 18px;
  height: 18px;
  fill: var(--blue-500);
}
.actions-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 16px;
}
.icon-action {
  cursor: pointer;
  transition: all 500ms;
}
.icon-more-active {
  transform: rotate(-180deg);
}
.toggle-profiles-wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: var(--blue-100);
  display: flex;
  align-items: center;
  justify-content: center;

  .chevron {
    width: 24px;
  }

  .badge {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 10;
  }
}
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ellipsis-wrapper {
  position: relative;
  width: auto;
  display: block;
  .ellipsis {
    position: absolute;
    left: 0;
    right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.new-health-plan {
  padding: 0.75rem 1rem;
  background-color: white;
  margin: 4px 0 -10px 0;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--blue-500);
  font-weight: 700;
  text-align: center;
}
</style>